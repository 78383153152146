import { Button, Modal } from "semantic-ui-react";
import React, { useCallback, useEffect, useState } from "react";
import { formatDate, getISODateTimeStamp, processDateString, toIsoDateString } from "../../services/Library";

import { AppContext } from "../../components/AppContextProvider";
import { Email } from "../order/types";
import { EmailQueueEntry } from "../../types";
import UseEmailService from "../../services/EmailService";
import config from "../../config";
import { termsServiceURL } from "../../services/Constants";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";
import useSproc from "../../hooks/useSproc";

interface ClientInfo {
    Name: string;
    ClientID: number;
    IsPrepay: boolean;
    BusinessLineID: number;
    InvoicedID: number;
    Address: string;
    City: string;
    State: string;
    ZIP: string;
    Country: string;
    Email: string;
}

const TermsConditions: React.FC = () => {
    const { clientUser } = useContext(AppContext);
    const { logout } = useAuth0();
    const [currentTCDate, setCurrentTCDate] = useState<string>("");
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const sproc = useSproc();
    const { getEmailService } = UseEmailService();

    const sendClientEmail = useCallback(
        async (email: Email): Promise<string | undefined> => {
            const { subject, to, body, SubmitID } = email;
            const from = config.noReplyEmail;
            const reply_to = config.noReplyEmail;

            try {
                const emailService = await getEmailService();
                const { message_id } = await emailService.send({
                    application: "fasano",
                    from: from,
                    subject: subject,
                    to: to,
                    body: body,
                    isHTML: true,
                    reply_to: reply_to
                });
                if (!message_id) {
                    throw new Error(`Error sending email`);
                }
                await sproc<EmailQueueEntry>("SetEmailQueue", {
                    DateSent: getISODateTimeStamp(),
                    Sender: from,
                    Recipient: to,
                    Subject: subject,
                    Body: body,
                    MessageID: message_id,
                    Notes: `Fasano Transmittal ID: ${SubmitID}`,
                    ReplyTo: reply_to
                });
                return message_id;
            } catch (error) {
                console.error(error);
            }
        },
        [getEmailService, sproc]
    );

    const sendTermsConfirmationEmail = useCallback(
        async (recipient: string, from: string): Promise<void> => {
            const body = `
            <p>Thank you for accepting the Terms and Conditions for use of our client portal.</p>
            <p>You may review the Terms and Conditions <a href="https://fasanoassociates.com/disclosures-and-privacy/">here</a>.</p>
            <p>If you have any questions, please contact:</p>
            <p>
                <strong>Customer Service</strong><br>
                Fasano Associates<br>
                202.457.8188<br>
                <a href="${from}">${from}</a>
            </p>
            <p>We appreciate your business.</p>
            <p><img src="https://fasanoassociates.com/wp-content/uploads/2020/03/Fasano-Logo-v2.5-Red.png"></p>`;

            const email: Email = {
                to: recipient,
                subject: `Receipt for Acceptance of Terms and Conditions`,
                body: body
            };
            try {
                const message_id = await sendClientEmail(email);
                if (!message_id) {
                    console.error(`Client email failed to send`);
                    return;
                }
            } catch (error) {
                throw new Error(error as string);
            }
        },
        [sendClientEmail]
    );

    const setTCAgreement = useCallback(async () => {
        if (!clientUser?.ClientID) {
            return;
        }
        setIsLoading(true);
        try {
            const clientInfo = await sproc<ClientInfo>("GetClientInfo", { ClientID: clientUser.ClientID });
            const [{ Email }] = clientInfo;
            await Promise.all([
                sendTermsConfirmationEmail(Email, config.noReplyEmail),
                sproc("SetFasanoClientWebGeneralTermsConditionsDate")
            ]);
            toast.success(`Thank you for accepting the terms and conditions`);
            setIsModalOpen(false);
        } catch (error) {
            toast.error(`Error accepting terms and conditions`);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [sendTermsConfirmationEmail, sproc, clientUser?.ClientID]);

    const getTCInformation = useCallback(async () => {
        try {
            const [data] = await sproc<{ ShowAgreement: boolean; CurrentTCDate: string }>("GetFasanoClientWebGeneralTermsConditionsDate");
            if (data) {
                setIsModalOpen(data.ShowAgreement);
                const currentTCDateParsed = processDateString(data.CurrentTCDate, true);
                setCurrentTCDate(formatDate(toIsoDateString(currentTCDateParsed)));
            }
        } catch (error) {
            console.error(error);
        }
    }, [sproc]);

    useEffect(() => {
        //Currently causing a memory leak
        getTCInformation();
    }, [getTCInformation]);

    return (
        <Modal
            open={isModalOpen}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={() => {
                setIsModalOpen(false);
            }}
        >
            <Modal.Header>Terms of Service</Modal.Header>
            <Modal.Content>
                Please{" "}
                <a style={{ textDecoration: "underline" }} href={`${termsServiceURL}`} rel="noopener noreferrer" target="_blank">
                    click here
                </a>{" "}
                to view the Terms of Service. Click &quot;Accept&quot; if you have read and agree with the Terms of Service.
                <br />
                <br />
                Last update: {currentTCDate}
            </Modal.Content>
            <Modal.Actions>
                <Button basic negative icon="times" content="Decline" onClick={() => logout()} />
                <Button positive disabled={isLoading} loading={isLoading} icon="checkmark" content="Accept" onClick={setTCAgreement} />
            </Modal.Actions>
        </Modal>
    );
};

export default TermsConditions;
